import React from "react";
import Button from "@material-ui/core/Button";
import RocketLander3d from "./images/rocket_lander_3d.webp";
import RocketLander3d_2 from "./images/rocket_lander_3d_2.webp";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import cleango1 from "./images/cleango/cleango1.webp";
import cleango2 from "./images/cleango/cleango2.webp";
import cleango3 from "./images/cleango/cleango3.webp";
import cleango4 from "./images/cleango/cleango4.webp";
import cleango5 from "./images/cleango/cleango5.webp";
import cleango6 from "./images/cleango/cleango6.webp";
import cleango7 from "./images/cleango/cleango7.webp";
import cleango8 from "./images/cleango/cleango8.webp";
import cleango10 from "./images/cleango/cleango10.webp";
import cleango11 from "./images/cleango/cleango11.webp";
import cleango12 from "./images/cleango/cleango12.webp";
import sabotage1 from "./images/sabotage/sabotage1.png";
import sabotage2 from "./images/sabotage/sabotage2.png";
import sabotage3 from "./images/sabotage/sabotage3.png";
import sabotage4 from "./images/sabotage/sabotage4.png";
import sabotage5 from "./images/sabotage/sabotage5.png";
import tetris1 from "./images/tetris1.png";
import tetris2 from "./images/tetris2.png";
export default function Games() {
    return (
        <div className="games-container" id="games">
            <div className="cards">
                <div className="card">
                    <div className="card-carousel">
                        <Carousel
                            autoPlay={false}
                            infiniteLoop
                            showThumbs={false}
                            dynamicHeight={false}
                            centerMode={true}
                        >
                            <div className="carousel-image-div">
                                <img src={tetris1} alt="tetris1" />
                            </div>
                            <div className="carousel-image-div">
                                <img src={tetris2} alt="tetris2" />
                            </div>
                        </Carousel>
                    </div>
                    <h2>Tetris Game</h2>
                    <p>Made with js</p>
                    <a href="https://servetg.com/tetris" target="_newblank">
                        <Button size="small" variant="contained">
                            Play
                        </Button>
                    </a>
                    <a
                        href="https://github.com/servetgulnaroglu/tetris-js"
                        target="_newblank"
                    >
                        <Button
                            size="small"
                            style={{ borderColor: "white", color: "white" }}
                            variant="outlined"
                        >
                            Source code
                        </Button>
                    </a>
                </div>
                <div className="card">
                    <div className="card-carousel">
                        <Carousel
                            autoPlay={false}
                            infiniteLoop
                            showThumbs={false}
                            dynamicHeight={false}
                            centerMode={true}
                        >
                            <div className="carousel-image-div">
                                <img
                                    src={RocketLander3d}
                                    alt="Rocket Lander 3D"
                                />
                            </div>
                            <div className="carousel-image-div">
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/W9yfiNll23w"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                ></iframe>
                            </div>
                            <div className="carousel-image-div">
                                <img
                                    src={RocketLander3d_2}
                                    alt="Rocket Lander 3D"
                                />
                            </div>
                        </Carousel>
                    </div>
                    <h2>Rocket Lander 3D</h2>
                    <p>
                        Try not to hit the walls! Reach to the landing pad! Test
                        yourself on infinite mode!
                    </p>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.arcking.RocketLander3D"
                        target="_newblank"
                    >
                        <Button size="small" variant="contained">
                            Play
                        </Button>
                    </a>
                </div>
                <div className="card">
                    <div className="card-carousel">
                        <Carousel
                            autoPlay={false}
                            infiniteLoop
                            showThumbs={false}
                            dynamicHeight={false}
                            centerMode={true}
                        >
                            <div className="carousel-image-div">
                                <img src={cleango11} alt="cleango" />
                            </div>
                            <div className="carousel-image-div">
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/Dvs2-pQEt4M"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                ></iframe>
                            </div>
                            <div className="carousel-image-div">
                                <img src={cleango12} alt="Rocket Lander 3D" />
                            </div>
                            <div className="carousel-image-div">
                                <img src={cleango1} alt="cleango" />
                            </div>
                            <div className="carousel-image-div">
                                <img src={cleango2} alt="cleango" />
                            </div>
                            <div className="carousel-image-div">
                                <img src={cleango3} alt="cleango" />
                            </div>
                            <div className="carousel-image-div">
                                <img src={cleango4} alt="cleango" />
                            </div>
                            <div className="carousel-image-div">
                                <img src={cleango5} alt="cleango" />
                            </div>
                            <div className="carousel-image-div">
                                <img src={cleango6} alt="cleango" />
                            </div>
                            <div className="carousel-image-div">
                                <img src={cleango7} alt="cleango" />
                            </div>
                            <div className="carousel-image-div">
                                <img src={cleango8} alt="cleango" />
                            </div>
                            <div className="carousel-image-div">
                                <img src={cleango10} alt="cleango" />
                            </div>

                            <div className="carousel-image-div">
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/zkH9OVC_B0E"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                ></iframe>
                            </div>
                        </Carousel>
                    </div>
                    <h2>Cleango</h2>
                    <p>
                        We come face to face with many mistakes and mistakes in
                        our lives. For example, to get low performance from the
                        game we are playing or to discover an application called
                        CleanGo to eliminate this problem. So, what do you think
                        these applications that claim to speed up your computer
                        actually do? This game, which consists of blood, sweat
                        and pixels in real terms, leaves you with a newfound
                        data and a very impatient man.
                    </p>
                    <a
                        href="https://teamsermath.itch.io/clean-go"
                        target="_newblank"
                    >
                        <Button size="small" variant="contained">
                            Play
                        </Button>
                    </a>
                </div>
                <div className="card">
                    <div className="card-carousel">
                        <Carousel
                            autoPlay={false}
                            infiniteLoop
                            showThumbs={false}
                            dynamicHeight={false}
                            centerMode={true}
                        >
                            <div className="carousel-image-div">
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/aH_QXHEi23Q"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                ></iframe>
                            </div>
                            <div className="carousel-image-div">
                                <img src={sabotage1} alt="sabotage" />
                            </div>
                            <div className="carousel-image-div">
                                <img src={sabotage2} alt="sabotage" />
                            </div>
                            <div className="carousel-image-div">
                                <img src={sabotage3} alt="sabotage" />
                            </div>
                            <div className="carousel-image-div">
                                <img src={sabotage4} alt="sabotage" />
                            </div>
                            <div className="carousel-image-div">
                                <img src={sabotage5} alt="sabotage" />
                            </div>
                        </Carousel>
                    </div>
                    <h2>Sabotage - A Heist Game</h2>
                    <p>
                        Sabotage the heist game is a RPG game with various
                        finals. Explore the bank with Ricardo. He is a selfish
                        pscyho that wants to have all the money in the world.
                        Help him in his operation.
                    </p>
                    <a
                        href="https://mehmetduhan.itch.io/sabotage-the-heist-game"
                        target="_newblank"
                    >
                        <Button size="small" variant="contained">
                            Play
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    );
}
