import React, {useState, useEffect} from 'react';

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(9);
  useEffect(() => {    
    var d = new Date();
    var year = d.getFullYear()
    setCurrentYear(year);
  }, [] )
  return <footer> <small> Copyright &copy; {currentYear} Servet Gulnaroglu. All Rights Reserved</small> </footer> 
}

export default Footer;