import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import imageRotations from "./rotations.png";
import yt from "./yt.png";
const SnakeAIPage = () => {
    class RLSnake {
        constructor() {
            this.alpha = 0.2;
            this.gamma = 0.1;
            gammaValueElement.value = this.gamma;
            alphaValueElement.value = this.alpha;
            this.noEatLoopCount = 0;
            this.maxNoEatLoopCount = 500;
            this.isAheadClearIndex = 0;
            this.isLeftClearIndex = 1;
            this.isRightClearIndex = 2;
            this.isAppleAheadIndex = 3;
            this.isAppleLeftIndex = 4;
            this.isAppleRightIndex = 5;
            this.initialState = [1, 1, 1, 0, 0, 0];
            this.state = this.initialState;
            this.Q_table = {};
        }

        calculateState() {
            this.state = this.initialState.slice();
            this.checkDirections();
        }

        update() {
            this.reward(this.state, this.getAction(this.state));
            this.checkDirections();
            let action = this.getAction(this.state);
            this.implementAction(action);
        }

        reward(state, action) {
            let rewardForState = 0;
            this.calculateState();
            let futureState = this.state;

            let stringifiedCurrentState = JSON.stringify(state);
            let stringifiedFutureState = JSON.stringify(futureState);
            if (stringifiedCurrentState != stringifiedFutureState) {
                if (
                    (state[0] == 0 && action == 0) ||
                    (state[1] == 0 && action == 1) ||
                    (state[2] == 0 && action == 2)
                ) {
                    rewardForState += -1;
                }
                if (
                    (state[this.isAheadClearIndex] == 1 &&
                        action == 0 &&
                        state[this.isAppleAheadIndex] == 1) ||
                    (state[this.isLeftClearIndex] == 1 &&
                        action == 1 &&
                        state[this.isAppleLeftIndex] == 1) ||
                    (state[this.isRightClearIndex] == 1 &&
                        action == 2 &&
                        state[this.isAppleRightIndex] == 1)
                ) {
                    rewardForState += 1;
                }
            }

            let optimumFutureValue = Math.max(
                this.getQ(futureState, 0),
                this.getQ(futureState, 1),
                this.getQ(futureState, 2)
            );

            let updateValue =
                this.alpha *
                    (rewardForState +
                        this.gamma * optimumFutureValue -
                        this.getQ(state, action)) -
                0.0001;
            this.setQ(state, action, updateValue);
        }

        implementAction(action) {
            if (typeof action === "undefined") return;
            if (!action) return;
            if (action == 0) return;
            let isRight = action == 2 ? -1 : 1;

            if (snake.rotateX == 1) {
                snake.rotateY = -1 * isRight;
                snake.rotateX = 0;
            } else if (snake.rotateX == -1) {
                snake.rotateY = 1 * isRight;
                snake.rotateX = 0;
            } else if (snake.rotateY == 1) {
                snake.rotateX = 1 * isRight;
                snake.rotateY = 0;
            } else if (snake.rotateY == -1) {
                snake.rotateX = -1 * isRight;
                snake.rotateY = 0;
            }
        }

        getQ(state, action) {
            let config = state.slice();
            config.push(action);
            if (!(config in this.Q_table)) {
                return 0;
            }
            return this.Q_table[config];
        }

        setQ(state, action, reward) {
            let config = state.slice();
            config.push(action);
            if (!(config in this.Q_table)) {
                this.Q_table[config] = 0;
            }
            this.Q_table[config] += reward;
        }

        getAction(state) {
            let q = {};
            for (let l = 0; l < 3; l++) {
                q[l] = this.getQ(state, l);
            }

            let items = Object.keys(q).map(function (key) {
                return [key, q[key]];
            });

            items.sort(function (first, second) {
                return second[1] - first[1];
            });
            q = items;

            let equalIndexCount = 1;
            if (q[0] == q[1]) {
                equalIndexCount++;
                if (q[1] == q[2]) equalIndexCount++;
            }

            if (!appleEated) {
                this.noEatLoopCount++;
            }
            if (this.noEatLoopCount > this.maxNoEatLoopCount) {
                this.noEatLoopCount = 0;
                //gameOver();
                return;
            }

            let key = Object.entries(q).sort((x, y) => y[1] - x[1])[0];
            //console.log(q, key[1][0]);
            return parseInt(key[1][0]);
        }

        checkDirections() {
            let correspondingSize;
            let headTail = snake.tail[snake.tail.length - 1];
            let rx = snake.rotateX;
            let ry = snake.rotateY;
            let size = snake.size;
            //wall
            if (
                (ry == 1 && headTail.x == 0) ||
                (rx == 1 && headTail.y + size == canvas.height) ||
                (ry == -1 && headTail.x + size == canvas.width) ||
                (rx == -1 && headTail.y == 0)
            ) {
                this.state[this.isRightClearIndex] = 0;
            }

            if (
                (ry == 1 && headTail.x + size == canvas.width) ||
                (rx == 1 && headTail.y == 0) ||
                (ry == -1 && headTail.x == 0) ||
                (rx == -1 && headTail.y + size == canvas.height)
            ) {
                this.state[this.isLeftClearIndex] = 0;
            }

            if (
                (ry == 1 && headTail.y + size == canvas.height) ||
                (rx == 1 && headTail.x + size == canvas.width) ||
                (ry == -1 && headTail.y == 0) ||
                (rx == -1 && headTail.x == 0)
            ) {
                this.state[this.isAheadClearIndex] = 0;
            }

            for (let i = 0; i < snake.tail.length - 2; i++) {
                let ithTail = snake.tail[i];
                if (rx == 0 && headTail.y == ithTail.y) {
                    correspondingSize = ry == 1 ? -size : size;
                    if (headTail.x == ithTail.x + correspondingSize) {
                        this.state[this.isLeftClearIndex] = 0;
                    }
                    if (headTail.x == ithTail.x - correspondingSize) {
                        this.state[this.isRightClearIndex] = 0;
                    }
                } else if (ry == 0 && headTail.x == ithTail.x) {
                    let correspondingSize = rx == 1 ? -size : size;
                    if (headTail.y == ithTail.y + correspondingSize) {
                        this.state[this.isRightClearIndex] = 0;
                    }
                    if (headTail.y == ithTail.y - correspondingSize) {
                        this.state[this.isLeftClearIndex] = 0;
                    }
                }
                if (
                    rx == 0 &&
                    headTail.x == ithTail.x &&
                    headTail.y + ry * size == ithTail.y
                ) {
                    this.state[this.isAheadClearIndex] = 0;
                }
                if (
                    ry == 0 &&
                    headTail.y == ithTail.y &&
                    headTail.x + rx * size == ithTail.x
                ) {
                    this.state[this.isAheadClearIndex] = 0;
                }
            }
            if (headTail.x == apple.x && ry != 0) {
                if (ry == 1 && headTail.y < apple.y)
                    this.state[this.isAppleAheadIndex] = 1;
                if (ry == -1 && headTail.y > apple.y)
                    this.state[this.isAppleAheadIndex] = 1;
            } else if (headTail.y == apple.y && rx != 0) {
                if (rx == 1 && headTail.x < apple.x)
                    this.state[this.isAppleAheadIndex] = 1;
                if (rx == -1 && headTail.x > apple.x)
                    this.state[this.isAppleAheadIndex] = 1;
            } else {
                let index = -1;
                if (ry == 1 && apple.x > headTail.x) {
                    index = this.isAppleLeftIndex;
                } else if (ry == 1 && apple.x < headTail.x) {
                    index = this.isAppleRightIndex;
                }
                if (ry == -1 && apple.x > headTail.x) {
                    index = this.isAppleRightIndex;
                } else if (ry == -1 && apple.x < headTail.x) {
                    index = this.isAppleLeftIndex;
                }
                if (rx == 1 && apple.y > headTail.y) {
                    index = this.isAppleRightIndex;
                } else if (rx == 1 && apple.y < headTail.y) {
                    index = this.isAppleLeftIndex;
                }
                if (rx == -1 && apple.y > headTail.y) {
                    index = this.isAppleLeftIndex;
                } else if (rx == -1 && apple.y < headTail.y) {
                    index = this.isAppleRightIndex;
                }
                if (index != -1) this.state[index] = 1;
            }
        }
    }

    class Snake {
        constructor() {
            this.initVars();
        }

        initVars() {
            this.x = 20;
            this.y = 20;
            this.size = 20;
            this.tail = [{ x: this.x, y: this.y }];
            this.rotateX = 0;
            this.rotateY = 1;
        }

        move() {
            let newRect;

            if (this.rotateX == 1) {
                newRect = {
                    x: this.tail[this.tail.length - 1].x + this.size,
                    y: this.tail[this.tail.length - 1].y,
                };
            } else if (this.rotateX == -1) {
                newRect = {
                    x: this.tail[this.tail.length - 1].x - this.size,
                    y: this.tail[this.tail.length - 1].y,
                };
            } else if (this.rotateY == 1) {
                newRect = {
                    x: this.tail[this.tail.length - 1].x,
                    y: this.tail[this.tail.length - 1].y + this.size,
                };
            } else if (this.rotateY == -1) {
                newRect = {
                    x: this.tail[this.tail.length - 1].x,
                    y: this.tail[this.tail.length - 1].y - this.size,
                };
            }

            this.tail.shift();
            this.tail.push(newRect);
        }
    }

    class Apple {
        constructor() {
            let isTouching;

            while (true) {
                isTouching = false;
                this.x =
                    Math.floor((Math.random() * canvas.width) / snake.size) *
                    snake.size;
                this.y =
                    Math.floor((Math.random() * canvas.height) / snake.size) *
                    snake.size;

                for (let i = 0; i < snake.tail.length; i++) {
                    if (
                        this.x == snake.tail[i].x &&
                        this.y == snake.tail[i].y
                    ) {
                        isTouching = true;
                    }
                }

                this.size = snake.size;
                this.color = "red";

                if (!isTouching) {
                    break;
                }
            }
        }
    }

    const size = 20;
    const [gameSpeed, setGameSpeed] = useState(1000);
    const [alpha, setAlpha] = useState(0.1);
    const [gamma, setGamma] = useState(0.1);
    let canvas;
    let canvasContext;
    let gameSpeedElement,
        highScore,
        averageScore,
        epochNumber,
        appleEated = false,
        gameInterval;

    let highScoreElement,
        epochNumberElement,
        alphaValueElement,
        gammaValueElement,
        averageScoreElement;
    const [modeIndex, setModeIndex] = useState(1);

    let snake;
    let apple;
    let rlSnake;

    useEffect(() => {
        window.addEventListener("keydown", (event) => {
            if (event.keyCode == 37 && snake.rotateX != 1) {
                snake.rotateX = -1;
                snake.rotateY = 0;
            } else if (event.keyCode == 38 && snake.rotateY != 1) {
                snake.rotateX = 0;
                snake.rotateY = -1;
            } else if (event.keyCode == 39 && snake.rotateX != -1) {
                snake.rotateX = 1;
                snake.rotateY = 0;
            } else if (event.keyCode == 40 && snake.rotateY != -1) {
                snake.rotateX = 0;
                snake.rotateY = 1;
            }
        });
        canvas = document.getElementById("canvas");
        canvasContext = canvas.getContext("2d");
        gameSpeedElement = document.getElementById("gameSpeed");
        setGameSpeed(1000);
        highScore = 0;
        averageScore = 0;
        epochNumber = 0;
        appleEated = false;
        highScoreElement = document.getElementById("highScore");
        averageScoreElement = document.getElementById("averageScore");
        epochNumberElement = document.getElementById("epochNumber");
        alphaValueElement = document.getElementById("alpha");
        gammaValueElement = document.getElementById("gamma");
        alphaValueElement.addEventListener("change", () => {
            rlSnake.alpha = parseFloat(alphaValueElement.value);
        });

        gammaValueElement.addEventListener("change", () => {
            rlSnake.gamma = parseFloat(gammaValueElement.value);
        });

        snake = new Snake();
        apple = new Apple();
        rlSnake = new RLSnake();
        gameLoop();
    }, []);

    let gameLoop = () => {
        const interval_id = window.setInterval(function () {},
        Number.MAX_SAFE_INTEGER);

        // Clear any timeout/interval up to that id
        for (let i = 1; i < interval_id; i++) {
            window.clearInterval(i);
        }
        gameInterval = setInterval(show, 1000 / gameSpeed);
    };

    function show() {
        update();
        draw();
    }

    function update() {
        canvasContext.clearRect(0, 0, canvas.width, canvas.height);
        snake.move();
        eatApple();
        rlSnake.update();
        checkCollision();
        appleEated = false;
    }

    function eatApple() {
        if (
            snake.tail[snake.tail.length - 1].x == apple.x &&
            snake.tail[snake.tail.length - 1].y == apple.y
        ) {
            snake.tail[snake.tail.length] = { x: apple.x, y: apple.y };
            apple = new Apple();
            appleEated = true;
        }
    }

    function gameOver() {
        highScore = Math.max(highScore, snake.tail.length - 1);
        highScoreElement.textContent = highScore;

        epochNumberElement.textContent = epochNumber++;

        averageScoreElement.textContent =
            (parseInt(averageScoreElement.textContent) * epochNumber +
                snake.tail.length -
                1) /
            epochNumber;
        snake.initVars();
    }

    function checkCollision() {
        let headTail = snake.tail[snake.tail.length - 1];
        if (
            headTail.x <= -snake.size || // wall collision
            headTail.x >= canvas.width ||
            headTail.y <= -snake.size ||
            headTail.y >= canvas.height
        ) {
            gameOver();
            return;
        }

        for (let i = 0; i < snake.tail.length - 2; i++) {
            // self collision
            if (
                headTail.x == snake.tail[i].x &&
                headTail.y == snake.tail[i].y
            ) {
                gameOver();
                return;
            }
        }
    }

    function draw() {
        createRect(0, 0, canvas.width, canvas.height, "black");
        createRect(0, 0, canvas.width, canvas.height);

        for (let i = 0; i < snake.tail.length; i++) {
            createRect(
                snake.tail[i].x + 2.5,
                snake.tail[i].y + 2.5,
                snake.size - 5,
                snake.size - 5,
                "white"
            );
        }

        canvasContext.font = "20px Arial";
        canvasContext.fillStyle = "#00FF42";
        canvasContext.fillText(
            "Score: " + (snake.tail.length - 1),
            canvas.width - 120,
            18
        );
        createRect(apple.x, apple.y, apple.size, apple.size, apple.color);
    }

    function createRect(x, y, width, height, color) {
        canvasContext.fillStyle = color;
        canvasContext.fillRect(x, y, width, height);
    }

    function resetSnake() {
        snake.initVars();
    }

    return (
        <>
            <div className="snakeAI">
                <canvas
                    id="canvas"
                    style={{
                        backgroundColor: "white",
                    }}
                    width="400"
                    height="400"
                >
                    {" "}
                </canvas>

                <div className="snakeAI-inputs">
                    <table>
                        <tr>
                            <td>High Score:</td>
                            <td id="highScore">0</td>
                        </tr>

                        <tr>
                            <td>Average Score:</td>
                            <td id="averageScore">0</td>
                        </tr>
                        <tr>
                            <td>Epoch Number: </td>
                            <td id="epochNumber">0</td>
                        </tr>
                        <tr>
                            <td>Speed: </td>
                            <td>
                                {" "}
                                <input
                                    type="text"
                                    value={gameSpeed}
                                    id="gameSpeed"
                                    onChange={(event) => {
                                        //setGameSpeed(event.target.value);
                                    }}
                                    disabled
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Alpha: </td>
                            <td>
                                <input
                                    type="text"
                                    disabled
                                    value={alpha}
                                    id="alpha"
                                    onChange={(event) => {
                                        //setAlpha(event.target.value);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Gamma</td>
                            <td>
                                <input
                                    type="text"
                                    value={gamma}
                                    id="gamma"
                                    onChange={(event) => {
                                        setGamma(event.target.value);
                                    }}
                                    disabled
                                />
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <button onclick={resetSnake} disabled>
                                    Reset
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>

                {/* <script src="game.js"></script> */}
                <footer
                    style={{
                        position: "absolute",
                        bottom: "0",
                        color: "white",
                        padding: "20px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "0",
                        marginTop: "0",
                        left: "0",
                        right: "0",
                        height: "max-content",
                        textAlign: "center",
                        background: "rgba(0,0,0,0.0)",
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                    }}
                >
                    <div>
                        Made with <span style={{ padding: "5px" }}> ❤️ </span>by
                        <a
                            style={{
                                color: "white !important",
                                padding: "5px",
                            }}
                            href="https://servetg.com/"
                            target="blank"
                        >
                            Servet Gulnaroglu
                        </a>
                        <span style={{ position: "relative" }}>
                            <a
                                href="https://www.youtube.com/c/servetgulnaroglu"
                                target="blank"
                            >
                                <img
                                    alt="yt logo"
                                    style={{ position: "relative", top: "6px" }}
                                    src={yt}
                                    width="30px"
                                />
                            </a>
                        </span>
                    </div>
                    <a
                        className="githubStartButtonA"
                        href="https://github.com/servetgulnaroglu/snake_AI"
                        target="_blank"
                        style={{ color: "white !important" }}
                    >
                        <div
                            className="githubStartButton"
                            style={{
                                backgroundColor: "#21262d",
                                padding: "10px",
                                margin: "10px",
                                borderRadius: "10px",
                                border: "2px solid rgba(0,0,0,0)",
                            }}
                        >
                            Give a{" "}
                            <span
                                style={{
                                    padding: "5px",
                                    position: "relative",
                                    top: "3px",
                                }}
                            >
                                {" "}
                                <svg
                                    aria-hidden="true"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    version="1.1"
                                    width="16"
                                    data-view-component="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        fill="#ffffff"
                                        d="M8 .25a.75.75 0 01.673.418l1.882 3.815 4.21.612a.75.75 0 01.416 1.279l-3.046 2.97.719 4.192a.75.75 0 01-1.088.791L8 12.347l-3.766 1.98a.75.75 0 01-1.088-.79l.72-4.194L.818 6.374a.75.75 0 01.416-1.28l4.21-.611L7.327.668A.75.75 0 018 .25zm0 2.445L6.615 5.5a.75.75 0 01-.564.41l-3.097.45 2.24 2.184a.75.75 0 01.216.664l-.528 3.084 2.769-1.456a.75.75 0 01.698 0l2.77 1.456-.53-3.084a.75.75 0 01.216-.664l2.24-2.183-3.096-.45a.75.75 0 01-.564-.41L8 2.694v.001z"
                                    ></path>
                                </svg>{" "}
                            </span>
                            on Github
                            <span
                                style={{
                                    position: "relative",
                                    paddingLeft: "5px",
                                    top: "2px",
                                }}
                            >
                                <svg
                                    height="16"
                                    aria-hidden="true"
                                    viewBox="0 0 16 16"
                                    version="1.1"
                                    width="16"
                                    data-view-component="true"
                                >
                                    <path
                                        fill="#ffffff"
                                        d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"
                                    ></path>
                                </svg>
                            </span>
                        </div>
                    </a>
                </footer>
            </div>
        </>
    );
};

export default SnakeAIPage;
