import React, { useState } from "react";
import servetg from "./images/4kmanzara.webp";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import MediumIcon from "./medium.js";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHamburger } from "@fortawesome/free-solid-svg-icons";
import { YouTube } from "@material-ui/icons";
const Nav = () => {
  const [fixNavbar, setFixNavbar] = useState("");
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const handleScroll = () => {
    if (document.body.scrollTop > window.innerHeight - 40) {
      setFixNavbar("fixed-navbar");
    } else {
      setFixNavbar("");
    }
  };

  const handleHamburgerClick = () => {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
    if (isHamburgerMenuOpen) {
    }
  };
  window.addEventListener("scroll", handleScroll, true);

  return (
    <>
      <header
        className="navbar"
        id="nav"
        style={{ backgroundImage: "url(" + servetg + ")" }}
      >
        <nav
          className={
            "header-nav " +
            fixNavbar +
            (isHamburgerMenuOpen ? " header-nav-backgorunded" : "")
          }
        >
          <li className={isHamburgerMenuOpen ? "header-nav-li-open-menu" : ""}>
            <a href="#nav">HOME</a>
          </li>
          <li className={isHamburgerMenuOpen ? "header-nav-li-open-menu" : ""}>
            <a href="#games">GAMES</a>
          </li>
          <li className={isHamburgerMenuOpen ? "header-nav-li-open-menu" : ""}>
            <a href="#art">ART</a>
          </li>
          <li className={isHamburgerMenuOpen ? "header-nav-li-open-menu" : ""}>
            <a href="#portfolio">WEBSITES</a>
          </li>
          <li className={isHamburgerMenuOpen ? "header-nav-li-open-menu" : ""}>
            <a href="#about">ABOUT</a>
          </li>
          <li className={isHamburgerMenuOpen ? "header-nav-li-open-menu" : ""}>
            <a href="#repos">REPOS</a>
          </li>
          <li className={isHamburgerMenuOpen ? "header-nav-li-open-menu" : ""}>
            <Link target="_newBlank" to="/resume">
              RESUME
            </Link>
          </li>
          <div className="header-nav-hamburger">
            <FontAwesomeIcon
              onClick={handleHamburgerClick}
              color="white"
              icon={faBars}
              size="2x"
            />
          </div>
        </nav>
        <div className="hello">
          <p>Hello, I'm</p>
          <h1>Servet Gulnaroglu</h1>
          <h3>call me servetg</h3>
          <p className="myresume">AND THIS IS MY RESUME</p>
          <div className="links">
            <a
              target="_newblank"
              href="https://www.linkedin.com/in/servet-gulnaroglu-978659145/"
            >
              <LinkedInIcon />
            </a>
            <a target="_newblank" href="https://github.com/servetgulnaroglu">
              <GitHubIcon />
            </a>
            <a target="_newblank" href="https://servet-gulnaroglu.medium.com/">
              <MediumIcon />
            </a>
            <a
              target="_newblank"
              href="https://www.youtube.com/channel/UCvPPXcl5dJiBgRKGTA8inPA/featured"
            >
              <YouTube />
            </a>
          </div>
        </div>
      </header>
    </>
  );
};

export default Nav;

