import React from "react";
import Mustang from "./images/mustang.webp";
import Button from "@material-ui/core/Button";
import cv from "./files/servetg_cv.pdf";
import treeAnim from "./images/tree_anim.webp";
import matrix from "./images/matrix.webp";
import fireworks from "./images/fireworks.webp";
import purple_rain from "./images/purple_rain.webp";
import snakeAI from "./images/snakeAI.webp";
const Art = () => (
    <div className="art-container" id="art">
        <div className="art" id="art">
            <div className="artContainer">
                <div className="artContent">
                    <div className="artTitle">
                        <h1>
                            I ❤️{" "}
                            <span
                                style={{
                                    fontSize: "40px",
                                    fontWeight: "bolder",
                                }}
                            >
                                {" "}
                                Art{" "}
                            </span>
                        </h1>
                    </div>
                    <div className="cards">
                        <div className="card">
                            <img src={treeAnim} alt="tree animation" />
                            <h2>Tree Animation</h2>
                            <p>A recursive tree visualization</p>
                            <a
                                href="https://tree-animation.web.app/"
                                target="_newblank"
                            >
                                <Button size="small" variant="contained">
                                    See project
                                </Button>
                            </a>
                        </div>
                        <div className="card">
                            <img src={matrix} alt="matrix animation" />
                            <h2>Matrix Flow</h2>
                            <p></p>
                            <a
                                href="https://ytb-matrix-js.web.app/"
                                target="_newblank"
                            >
                                <Button size="small" variant="contained">
                                    See project
                                </Button>
                            </a>
                        </div>
                        <div className="card">
                            <img src={snakeAI} alt="matrix animation" />
                            <h2>Snake AI with Reinforcement Learning</h2>
                            <p></p>
                            <a
                                href="https://www.servetg.com/snakeAI/"
                                target="_newblank"
                            >
                                <Button size="small" variant="contained">
                                    See project
                                </Button>
                            </a>
                        </div>
                        <div className="card">
                            <img src={fireworks} alt="fireworks animation" />
                            <h2>Firework Animation</h2>
                            <p></p>
                            <a
                                href="https://ytb-firework-js.web.app/"
                                target="_newblank"
                            >
                                <Button size="small" variant="contained">
                                    See project
                                </Button>
                            </a>
                        </div>
                        <div className="card">
                            <img
                                src={purple_rain}
                                alt="purple rain animation"
                            />
                            <h2>Purple Rain</h2>
                            <p></p>
                            <a
                                href="https://purple-rain-69788.web.app/"
                                target="_newblank"
                            >
                                <Button size="small" variant="contained">
                                    See project
                                </Button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Art;
