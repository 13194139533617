import React from "react";
import instalike from "./images/instalike.webp";
import taskmanager from "./images/taskmanager.webp";
import machinegame from "./images/machinegame.webp";
import Button from "@material-ui/core/Button";
import personalpage from "./images/personalpage.webp";
import netflixclone from "./images/netflix-clone.webp";
import amazonclone from "./images/amazonclone.webp";
import ozelgunsipariscisi from "./images/ozelgunsipariscisi.webp";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import ev1 from './images/electricliaraclar/ev1.webp'
import ev2 from './images/electricliaraclar/ev2.webp'
import ev3 from './images/electricliaraclar/ev3.webp'
import ev4 from './images/electricliaraclar/ev4.webp'
import ev5 from './images/electricliaraclar/ev5.webp'
import ev6 from './images/electricliaraclar/ev6.webp'
import ev7 from './images/electricliaraclar/ev7.webp'
import ev8 from './images/electricliaraclar/ev8.webp'



const Portfolio = () => {
  return (
    <div className="portfolio-container">
      <div className="portfolio" id="portfolio">
        <div className="portfolioTitle">
          <h1>
            <span className="portfolioTitle2">websites</span>
          </h1>
        </div>
        <div className="cards">
          <div className="card">
              <div className="card-carousel">
                  <Carousel autoPlay={false} infiniteLoop showThumbs={false} dynamicHeight={false}  centerMode={true}>
                      <div className="carousel-image-div">   
                          <img src={ev1} alt="sabotage" />
                      </div>
                      <div className="carousel-image-div">   
                          <img src={ev2} alt="sabotage" />
                      </div>
                      <div className="carousel-image-div">   
                          <img src={ev3} alt="sabotage" />
                      </div>
                      <div className="carousel-image-div">   
                          <img src={ev4} alt="sabotage" />
                      </div>
                      <div className="carousel-image-div">   
                          <img src={ev5} alt="sabotage" />
                      </div>
                      <div className="carousel-image-div">   
                          <img src={ev6} alt="sabotage" />
                      </div>
                      <div className="carousel-image-div">   
                          <img src={ev7} alt="sabotage" />
                      </div>
                      <div className="carousel-image-div">   
                          <img src={ev8} alt="sabotage" />
                      </div>
                  </Carousel>
              </div>
              <h2>Electric Vehicles</h2>
              <p>
              A complete overview of all electric vehicles in Europe. Search and compare by range, make, model and price.
              </p>
              <a href="https://www.electricliaraclar.com/" target="_newblank">
                <Button size="small" variant="contained">
                  Visit Page
                </Button>
              </a>
            </div>
          <div className="card">
            <img src={ozelgunsipariscisi} alt="amazonclone" />
            <h2>@ozelgunsipariscisi menu page</h2>
            <p>
              It is built to facilitate shopping. Clients create a shopping
              basket, and copy the order to send instagram page. That way, clients
              will have better experience being not to request the menu every
              order.
            </p>
            <a href="https://ozelgunsipariscisi.com/" target="_newblank">
              <Button size="small" variant="contained">
                See project
              </Button>
            </a>
            <a
              href="https://www.instagram.com/ozel_gun_sipariscisi_eskisehir/"
              target="_newblank"
            >
              <Button
                size="small"
                style={{ borderColor: "white", color: "white" }}
                variant="outlined"
              >
                @ozelgunsipariscisi
              </Button>
            </a>
          </div>

          <div className="card">
            <img src={amazonclone} alt="amazonclone" />
            <h2>Amazon clone</h2>
            <p>
              Includes sign in, basket, payment functionalities. Built with
              #react, hosted with firebase. Uses 'functions' back-end server to
              auth payment.
            </p>
            <a href="https://e-clone-78f35.web.app/" target="_newblank">
              <Button size="small" variant="contained">
                See project
              </Button>
            </a>
          </div>

          <div className="card">
            <img src={instalike} alt="" />
            <h2>Instagram like app</h2>
            <p>
              An app that likes photos in community periodically. App allows user
              to gain many like (150-300) per event. Mostly used selenium in this
              project.
            </p>
            <a
              href="https://instagramlikeplatform.herokuapp.com/intro/"
              target="_newblank"
            >
              <Button size="small" variant="contained">
                See project
              </Button>
            </a>
          </div>
          <div className="card">
            <img src={netflixclone} alt="netflix-clone" />
            <h2>Netflix clone</h2>
            <p>
              Homepage of netflix, with trailer functionality. Built with #react
              and deployed with #firebase.
            </p>
            <a href="https://netflix-clone-48dac.web.app/" target="_newblank">
              <Button size="small" variant="contained">
                See project
              </Button>
            </a>
            <a
              href="https://github.com/servetgulnaroglu/netflix-clone"
              target="_newblank"
            >
              <Button
                size="small"
                style={{ borderColor: "white", color: "white" }}
                variant="outlined"
              >
                Source code
              </Button>
            </a>
          </div>
          <div className="card">
            <img src={personalpage} alt="" />
            <h2>My personal page</h2>
            <p>
              The current page you are visiting. Shows my projects, github
              repositories, cv and so on.
            </p>
            <a
              href="https://github.com/servetgulnaroglu/personalwebsite"
              target="_newblank"
            >
              <Button
                size="small"
                style={{ borderColor: "white", color: "white" }}
                variant="outlined"
              >
                Source code
              </Button>
            </a>
          </div>
          <div className="card">
            <img src={taskmanager} alt="" />
            <h2>Taskmanager</h2>
            <p>
              A website app that helps user to plan time. It uses mongodb as
              database, and includes authentication.
            </p>
            <a
              href="https://taskmanager-js.herokuapp.com/login"
              target="_newblank"
            >
              <Button size="small" variant="contained">
                See project
              </Button>
            </a>
          </div>
          <div className="card">
            <img src={machinegame} alt="" />
            <h2>Multiplayer machine game</h2>
            <p>
              You have a machine that can fire. Kill your enemies, improve your
              weapons. A funny time wasting game! Built with socket.io.
            </p>
            <a
              href="https://multiplayer-game-js.herokuapp.com/"
              target="_newblank"
            >
              <Button size="small" variant="contained">
                Play game
              </Button>
            </a>
            <a
              href="https://github.com/servetgulnaroglu/multiplayer-game-with-javascript"
              target="_newblank"
            >
              <Button
                size="small"
                style={{ borderColor: "white", color: "white" }}
                variant="outlined"
              >
                Source code
              </Button>
            </a>
          </div>
        </div>
      </div>
      </div>
  );
};

export default Portfolio;
