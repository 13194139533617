import React from "react";
import Viewer, { Worker, SpecialZoomLevel } from "@phuocng/react-pdf-viewer";

import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import cv from "./files/servetg_cv.pdf";
const ResumePdf = () => {
  return (
    <div className="resumePdf">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
        <div style={{ height: "100vh" }}>
          <Viewer fileUrl={cv} defaultScale={SpecialZoomLevel.PageFit} />
        </div>
      </Worker>
    </div>
  );
};

export default ResumePdf;
