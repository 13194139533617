import React, { useState, useEffect } from "react";
import "../App.css";
import Nav from "./Nav";
import Portfolio from "./Portfolio";
import About from "./About";
import GithubHeader from "./GithubHeader";
import Github from "./Github";
import Footer from "./Footer";
import Games from "./Games";
import Art from "./Art";
function Home() {
  const [github, setGithub] = useState();
  const [githubRepos, setGithubRepos] = useState();

  useEffect(() => {
    async function getGithubData() {
      fetch("https://api.github.com/users/servetgulnaroglu")
        .then((response) => response.json())
        .then((data) => setGithub(data));
      fetch("https://api.github.com/users/servetgulnaroglu/repos")
        .then((response) => response.json())
        .then((data) => setGithubRepos(data));
    }
    getGithubData();
  }, []);

  return (
    <div className="home" id="home">
      <Nav />
      <Games />
      <Art />
      <Portfolio />
      {/* <Resume/> */}
      <About />
      {github !== undefined ? <GithubHeader data={github} /> : ""}
      {githubRepos !== undefined ? <Github data={githubRepos} /> : ""}
      <Footer />
    </div>
  );
}

export default Home;
