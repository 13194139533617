import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import imageRotations from "./rotations.png";
import yt from "./yt.png";
const TetrisPage = () => {
    class Tetris {
        constructor(imageX, imageY, template) {
            this.imageY = imageY;
            this.imageX = imageX;
            this.template = template;
            this.x = 4;
            this.y = -3;
        }

        checkBottom() {
            for (let i = 0; i < this.template.length; i++) {
                for (let j = 0; j < this.template.length; j++) {
                    if (this.template[i][j] == 0) continue;
                    let realX = i + this.getTruncedPosition().x;
                    let realY = j + this.getTruncedPosition().y;
                    if (realY + 1 >= squareCountY) {
                        return false;
                    }
                    if (realY + 1 < 0) continue;
                    if (gameMap.length === 0) continue;
                    if (gameMap[realY + 1][realX].imageX !== -1) {
                        return false;
                    }
                }
            }
            return true;
        }

        getTruncedPosition() {
            return { x: Math.trunc(this.x), y: Math.trunc(this.y) };
        }
        checkLeft() {
            for (let i = 0; i < this.template.length; i++) {
                for (let j = 0; j < this.template.length; j++) {
                    if (this.template[i][j] == 0) continue;
                    let realX = i + this.getTruncedPosition().x;
                    let realY = j + this.getTruncedPosition().y;
                    if (realX - 1 < 0) {
                        return false;
                    }
                    if (realY < 0) continue;
                    if (gameMap[realY][realX - 1].imageX != -1) {
                        return false;
                    }
                }
            }
            return true;
        }

        checkRight() {
            for (let i = 0; i < this.template.length; i++) {
                for (let j = 0; j < this.template.length; j++) {
                    if (this.template[i][j] == 0) continue;
                    let realX = i + this.getTruncedPosition().x;
                    let realY = j + this.getTruncedPosition().y;
                    if (realX + 1 >= squareCountX) {
                        return false;
                    }
                    if (realY < 0) continue;
                    if (gameMap[realY][realX + 1].imageX !== -1) return false;
                }
            }
            return true;
        }

        moveRight() {
            if (this.checkRight()) {
                this.x += 1;
            }
        }

        moveLeft() {
            if (this.checkLeft()) {
                this.x -= 1;
            }
        }

        moveBottom() {
            if (this.checkBottom()) {
                this.y += 1;
                score += 1;
            }
        }
        changeRotation() {
            let tempTemplate = [];
            for (let i = 0; i < this.template.length; i++)
                tempTemplate[i] = this.template[i].slice();
            let n = this.template.length;
            for (let layer = 0; layer < n / 2; layer++) {
                let first = layer;
                let last = n - 1 - layer;
                for (let i = first; i < last; i++) {
                    let offset = i - first;
                    let top = this.template[first][i];
                    this.template[first][i] = this.template[i][last]; // top = right
                    this.template[i][last] = this.template[last][last - offset]; //right = bottom
                    this.template[last][last - offset] =
                        this.template[last - offset][first];
                    //bottom = left
                    this.template[last - offset][first] = top; // left = top
                }
            }

            for (let i = 0; i < this.template.length; i++) {
                for (let j = 0; j < this.template.length; j++) {
                    if (this.template[i][j] == 0) continue;
                    let realX = i + this.getTruncedPosition().x;
                    let realY = j + this.getTruncedPosition().y;
                    if (realY < 0) continue;
                    if (
                        realX < 0 ||
                        realX >= squareCountX ||
                        gameMap[realY][realX].imageX !== -1
                    ) {
                        this.template = tempTemplate;
                        return false;
                    }
                }
            }
            for (let i = 0; i < this.template.length; i++) {
                for (let j = 0; j < this.template.length; j++) {
                    if (this.template[i][j] == 0) continue;
                    let realX = i + this.getTruncedPosition().x;

                    let realY = j + this.getTruncedPosition().y;
                    if (realY + 1 < 0) continue;
                    if (
                        realX < 0 ||
                        realX >= squareCountX ||
                        realY < 0 ||
                        realY >= squareCountY
                    ) {
                        this.template = tempTemplate;
                        return false;
                    }
                }
            }
        }
    }

    const imageSquareSize = 24;
    const size = 40;
    const framePerSecond = 24;
    const [gameSpeed, setGameSpeed] = useState(5);
    let canvas;
    let nextShapeCanvas;
    let scoreCanvas;
    let ctx;
    let nctx;
    let sctx;
    let squareCountX;
    let squareCountY;
    let image;

    let gameMap;
    let gameOver;
    let currentShape;
    let nextShape;
    let score;
    let whiteLineThickness = 4;
    const [modeIndex, setModeIndex] = useState(1);

    useEffect(() => {
        window.addEventListener("keydown", (event) => {
            if (event.keyCode == 37) {
                currentShape.moveLeft();
            } else if (event.keyCode == 38) currentShape.changeRotation();
            else if (event.keyCode == 39) currentShape.moveRight();
            else if (event.keyCode == 40) currentShape.moveBottom();
        });
        canvas = document.getElementById("canvas");
        nextShapeCanvas = document.getElementById("nextShapeCanvas");
        scoreCanvas = document.getElementById("scoreCanvas");
        ctx = canvas.getContext("2d");
        nctx = nextShapeCanvas.getContext("2d");
        sctx = scoreCanvas.getContext("2d");
        image = document.getElementById("image");
        squareCountX = canvas.width / size;
        squareCountY = canvas.height / size;
        gameLoop();
    }, []);

    const shapes = [
        new Tetris(0, 120, [
            [0, 1, 0],
            [0, 1, 0],
            [1, 1, 0],
        ]),
        new Tetris(0, 96, [
            [0, 0, 0],
            [1, 1, 1],
            [0, 1, 0],
        ]),
        new Tetris(0, 72, [
            [0, 1, 0],
            [0, 1, 0],
            [0, 1, 1],
        ]),
        new Tetris(0, 48, [
            [0, 0, 0],
            [0, 1, 1],
            [1, 1, 0],
        ]),
        new Tetris(0, 24, [
            [0, 0, 1, 0],
            [0, 0, 1, 0],
            [0, 0, 1, 0],
            [0, 0, 1, 0],
        ]),
        new Tetris(0, 0, [
            [1, 1],
            [1, 1],
        ]),

        new Tetris(0, 48, [
            [0, 0, 0],
            [1, 1, 0],
            [0, 1, 1],
        ]),
    ];

    let deleteCompleteRows = () => {
        for (let i = 0; i < gameMap.length; i++) {
            let t = gameMap[i];
            let isComplete = true;
            for (let j = 0; j < t.length; j++) {
                if (t[j].imageX == -1) isComplete = false;
            }
            if (isComplete) {
                score += 1000;
                for (let k = i; k > 0; k--) {
                    gameMap[k] = gameMap[k - 1];
                }
                let temp = [];
                for (let j = 0; j < squareCountX; j++) {
                    temp.push({ imageX: -1, imageY: -1 });
                }
                gameMap[0] = temp;
            }
        }
    };

    let update = () => {
        if (gameOver) return;
        if (currentShape.checkBottom()) {
            currentShape.y += 1;
        } else {
            for (let k = 0; k < currentShape.template.length; k++) {
                for (let l = 0; l < currentShape.template.length; l++) {
                    if (currentShape.template[k][l] === 0) continue;
                    if (currentShape.getTruncedPosition().y + l < 0) continue;
                    gameMap[currentShape.getTruncedPosition().y + l][
                        currentShape.getTruncedPosition().x + k
                    ] = {
                        imageX: currentShape.imageX,
                        imageY: currentShape.imageY,
                    };
                }
            }

            deleteCompleteRows();
            currentShape = nextShape;
            nextShape = getRandomShape();
            if (!currentShape.checkBottom()) {
                gameOver = true;
            }
            score += 100;
        }
    };

    let drawRect = (x, y, width, height, color) => {
        ctx.fillStyle = color;
        ctx.fillRect(x, y, width, height);
    };

    let drawBackground = () => {
        drawRect(0, 0, canvas.width, canvas.height, "#bca0dc");
        for (let i = 0; i < squareCountX + 1; i++) {
            drawRect(
                size * i - whiteLineThickness,
                0,
                whiteLineThickness,
                canvas.height,
                "white"
            );
        }

        for (let i = 0; i < squareCountY + 1; i++) {
            drawRect(
                0,
                size * i - whiteLineThickness,
                canvas.width,
                whiteLineThickness,
                "white"
            );
        }
    };

    let drawCurrentTetris = () => {
        for (let i = 0; i < currentShape.template.length; i++) {
            for (let j = 0; j < currentShape.template.length; j++) {
                if (currentShape.template[i][j] == 0) continue;
                if (!image) return;
                ctx.drawImage(
                    image,
                    currentShape.imageX,
                    currentShape.imageY,
                    imageSquareSize,
                    imageSquareSize,
                    Math.trunc(currentShape.x) * size + size * i,
                    Math.trunc(currentShape.y) * size + size * j,
                    size,
                    size
                );
            }
        }
    };

    let drawSquares = () => {
        for (let i = 0; i < gameMap.length; i++) {
            let t = gameMap[i];
            for (let j = 0; j < t.length; j++) {
                if (t[j].imageX == -1) continue;
                ctx.drawImage(
                    image,
                    t[j].imageX,
                    t[j].imageY,
                    imageSquareSize,
                    imageSquareSize,
                    j * size,
                    i * size,
                    size,
                    size
                );
            }
        }
    };

    let drawNextShape = () => {
        nctx.fillStyle = "#bca0dc";
        nctx.fillRect(0, 0, nextShapeCanvas.width, nextShapeCanvas.height);
        for (let i = 0; i < nextShape.template.length; i++) {
            for (let j = 0; j < nextShape.template.length; j++) {
                if (nextShape.template[i][j] == 0) continue;
                nctx.drawImage(
                    image,
                    nextShape.imageX,
                    nextShape.imageY,
                    imageSquareSize,
                    imageSquareSize,
                    size * i,
                    size * j + size,
                    size,
                    size
                );
            }
        }
    };

    let drawScore = () => {
        sctx.clearRect(0, 0, scoreCanvas.width, scoreCanvas.height);
        sctx.font = "64px Poppins";
        sctx.fillStyle = "black";
        sctx.fillText(score, 10, 50);
    };

    let drawGameOver = () => {
        ctx.font = "64px Poppins";
        ctx.fillStyle = "black";
        ctx.fillText("Game Over!", 10, canvas.height / 2);
    };

    let draw = () => {
        if (ctx == null) return;
        if (!ctx) return;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        drawBackground();
        drawSquares();
        drawCurrentTetris();
        drawNextShape();
        drawScore();
        if (gameOver) {
            drawGameOver();
        }
    };

    let getRandomShape = () => {
        return Object.create(shapes[Math.floor(Math.random() * shapes.length)]);
    };
    let setGameMap = () => {
        gameMap = [];
        for (let i = 0; i < squareCountY; i++) {
            let temp = [];
            for (let j = 0; j < squareCountX; j++) {
                temp.push({ imageX: -1, imageY: -1 });
            }
            gameMap.push(temp);
        }
    };

    let resetVars = () => {
        setGameMap();
        score = 0;
        gameOver = false;
        currentShape = getRandomShape();
        nextShape = getRandomShape();
    };

    let gameLoop = () => {
        const interval_id = window.setInterval(function () {},
        Number.MAX_SAFE_INTEGER);

        // Clear any timeout/interval up to that id
        for (let i = 1; i < interval_id; i++) {
            window.clearInterval(i);
        }

        resetVars();

        console.log(1000 / gameSpeed);
        setInterval(update, 1000 / gameSpeed);
        setInterval(draw, 1000 / framePerSecond);
    };

    return (
        <>
            <div className="tetris">
                <canvas
                    id="canvas"
                    style={{
                        paddingTop: "4px",
                        backgroundColor: "white",
                        paddingLeft: "4px",
                    }}
                    width="400"
                    height="800"
                >
                    {" "}
                </canvas>
                <canvas
                    id="nextShapeCanvas"
                    width="160"
                    height="160"
                    style={{
                        position: "absolute",
                        top: "10px",
                        padding: "10px",
                    }}
                ></canvas>

                <canvas
                    id="scoreCanvas"
                    width="200"
                    height="60"
                    style={{ position: "absolute", top: " 190px" }}
                ></canvas>
                {/* 
                <span className="tetrisModeButtons">
                    <button
                        className={
                            "tetrisModeButton " +
                            (modeIndex == 0 ? "choosedTetrisMode" : "")
                        }
                        style={{
                            borderTopLeftRadius: "20px",
                            borderBottomLeftRadius: "20px",
                        }}
                        onClick={() => {
                            setGameSpeed(3);
                            setModeIndex(0);
                        }}
                    >
                        Easy
                    </button>

                    <button
                        style={{ borderLeft: "1px solid white" }}
                        className={
                            "tetrisModeButton " +
                            (modeIndex == 1 ? "choosedTetrisMode" : "")
                        }
                        onClick={() => {
                            setGameSpeed(5);
                            setModeIndex(1);
                        }}
                    >
                        Medium
                    </button>

                    <button
                        style={{
                            borderLeft: "1px solid white",

                            borderTopRightRadius: "20px",
                            borderBottomRightRadius: "20px",
                        }}
                        className={
                            "tetrisModeButton " +
                            (modeIndex == 2 ? "choosedTetrisMode" : "")
                        }
                        onClick={() => {
                            setGameSpeed(8);
                            setModeIndex(2);
                        }}
                    >
                        Hard
                    </button>
                </span> */}

                <button
                    className="restartButton"
                    style={{
                        width: "64px",
                        height: "64px",
                        border: "0px",
                        borderRadius: "50%",
                        backgroundColor: "gray",
                        marginLeft: "10px",
                    }}
                    onClick={gameLoop}
                >
                    <svg
                        height="100%"
                        version="1.1"
                        viewBox="0 0 36 36"
                        width="100%"
                    >
                        <path
                            className="ytp-svg-fill"
                            fill="#ffffff"
                            d="M 18,11 V 7 l -5,5 5,5 v -4 c 3.3,0 6,2.7 6,6 0,3.3 -2.7,6 -6,6 -3.3,0 -6,-2.7 -6,-6 h -2 c 0,4.4 3.6,8 8,8 4.4,0 8,-3.6 8,-8 0,-4.4 -3.6,-8 -8,-8 z"
                            id="ytp-id-54"
                        ></path>
                    </svg>
                </button>
                <img
                    id="image"
                    alt="restart"
                    src={imageRotations}
                    style={{ display: "none" }}
                />
                <footer
                    style={{
                        position: "absolute",
                        bottom: "0",
                        color: "white",
                        padding: "20px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "0",
                        marginTop: "0",
                        left: "0",
                        right: "0",
                        height: "max-content",
                        textAlign: "center",
                        background: "rgba(0,0,0,0.0)",
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                    }}
                >
                    <div>
                        Made with <span style={{ padding: "5px" }}> ❤️ </span>by
                        <a
                            style={{
                                color: "white !important",
                                padding: "5px",
                            }}
                            href="https://servetg.com/"
                            target="blank"
                        >
                            Servet Gulnaroglu
                        </a>
                        <span style={{ position: "relative" }}>
                            <a
                                href="https://www.youtube.com/c/servetgulnaroglu"
                                target="blank"
                            >
                                <img
                                    alt="yt logo"
                                    style={{ position: "relative", top: "6px" }}
                                    src={yt}
                                    width="30px"
                                />
                            </a>
                        </span>
                    </div>
                    <a
                        className="githubStartButtonA"
                        href="https://github.com/servetgulnaroglu/tetris-js"
                        target="_blank"
                        style={{ color: "white !important" }}
                    >
                        <div
                            className="githubStartButton"
                            style={{
                                backgroundColor: "#21262d",
                                padding: "10px",
                                margin: "10px",
                                borderRadius: "10px",
                                border: "2px solid rgba(0,0,0,0)",
                            }}
                        >
                            Give a{" "}
                            <span
                                style={{
                                    padding: "5px",
                                    position: "relative",
                                    top: "3px",
                                }}
                            >
                                {" "}
                                <svg
                                    aria-hidden="true"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    version="1.1"
                                    width="16"
                                    data-view-component="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        fill="#ffffff"
                                        d="M8 .25a.75.75 0 01.673.418l1.882 3.815 4.21.612a.75.75 0 01.416 1.279l-3.046 2.97.719 4.192a.75.75 0 01-1.088.791L8 12.347l-3.766 1.98a.75.75 0 01-1.088-.79l.72-4.194L.818 6.374a.75.75 0 01.416-1.28l4.21-.611L7.327.668A.75.75 0 018 .25zm0 2.445L6.615 5.5a.75.75 0 01-.564.41l-3.097.45 2.24 2.184a.75.75 0 01.216.664l-.528 3.084 2.769-1.456a.75.75 0 01.698 0l2.77 1.456-.53-3.084a.75.75 0 01.216-.664l2.24-2.183-3.096-.45a.75.75 0 01-.564-.41L8 2.694v.001z"
                                    ></path>
                                </svg>{" "}
                            </span>
                            on Github
                            <span
                                style={{
                                    position: "relative",
                                    paddingLeft: "5px",
                                    top: "2px",
                                }}
                            >
                                <svg
                                    height="16"
                                    aria-hidden="true"
                                    viewBox="0 0 16 16"
                                    version="1.1"
                                    width="16"
                                    data-view-component="true"
                                >
                                    <path
                                        fill="#ffffff"
                                        d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"
                                    ></path>
                                </svg>
                            </span>
                        </div>
                    </a>
                </footer>
            </div>
        </>
    );
};

export default TetrisPage;
