import React from "react";
import Mustang from "./images/mustang.webp";
import Button from "@material-ui/core/Button";
import cv from "./files/servetg_cv.pdf";
const About = () => (
  <div className="about-container">
    <div className="about" id="about">
      <div className="aboutContainer">
        <img src={Mustang} alt="" />
        <div className="aboutContent">
          <h1>
            About <span className="meSpan"> me</span>
          </h1>
          <p>
            I'm a sophomore Computer Science at Bilkent University. I like
            learning programming.
          </p>
          <p>
            I'm into Unity and Blender. Currently, we develop an educational
            game called 'Pandemic Runner' for the Ministry of National
            Educationt of Turkey as Team Sermath.
          </p>
          <a href={cv} target="_blank" rel="noopener noreferrer" download>
            <Button
              style={{
                borderColor: "white",
                color: "white",
                marginTop: "20px",
              }}
              variant="outlined"
            >
              Download CV
            </Button>
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default About;
