import React from "react";
import "./App.css";

//import components

import ResumePdf from "./components/ResumePdf";
import Home from "./components/Home";
import PrivacyPolicy from "./components/PrivacyPolicy";
import SliceThatPrivacyPolicy from "./components/SliceThatPrivacyPolicy";
import SciFiRunPrivacyPolicy from "./components/SciFiRunPrivacyPolicy";
import JuicyMadnessPrivacyPolicy from "./components/JuicyMadnessPrivacyPolicy";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NotFound from "./components/NotFound";
import TetrisPage from "./components/TetrisPage";
import SnakeAIPage from "./components/SnakeAIPage";
const App = () => {
    return (
        <Router>
            <div className="App">
                <Switch>
                    <Route exact path="/" component={() => <Home />} />
                    <Route path="/resume" component={ResumePdf} />
                    <Route
                        path="/googlePlayPrivacyPolicy"
                        component={PrivacyPolicy}
                    />
                    <Route
                        path="/teamSermath/SliceThatPrivacyPolicy"
                        component={SliceThatPrivacyPolicy}
                    />
                    <Route
                        path="/teamSermath/SciRunPrivacyPolicy"
                        component={SciFiRunPrivacyPolicy}
                    />
                    <Route
                        path="/teamSermath/JuicyMadnessPrivacyPolicy"
                        component={JuicyMadnessPrivacyPolicy}
                    />
                    <Route path="/tetris" component={TetrisPage} />
                    <Route path="/snakeAI" component={SnakeAIPage} />
                    <Route component={NotFound} />
                </Switch>
            </div>
        </Router>
    );
};

export default App;
